import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CategoryType } from '../common/category-model';
import { SearchUser } from '../common/user-model';
import { 
  acceptSendToMeCategoriesService, 
  cancelSendToMeCategoriesService, 
  CancelSendToMeCategoryRequestParams, 
  getSendToMeCategoriesService, 
  RequestParams, 
  searchUsersService, 
  sendToFriendCategoryService, 
  UserSearchParams
} from '../services/sendToUsersCategoryAxiosServices';
import { RootState } from './store';


interface CategoriesType {
  allCategories: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "description" | "creatorNames" | "cardsLength" | "categoryType">[];
  counts: number | null;
  pages: number;
  getCategoriesLoading: boolean;
  getCategoriesError: string | undefined;
  sendCategoryToUsersLoading: boolean;
  sendCategoryToUsersError: string | undefined;
  acceptFromUserLoading: boolean;
  acceptCategoriesError: string | undefined;
  cancelFromUserLoading: boolean;
  cancelCategoriesError: string | undefined;
  searchUsersLoading: boolean;
  searchUsersError: string | undefined;
  searchUsersList: SearchUser[]
}

export const initialState: CategoriesType = {
  allCategories: [],
  counts: null,
  pages: 0,
  getCategoriesLoading: false,
  getCategoriesError: undefined,
  sendCategoryToUsersLoading: false,
  sendCategoryToUsersError: "",
  acceptFromUserLoading: false,
  acceptCategoriesError: undefined,
  cancelFromUserLoading: false,
  cancelCategoriesError: undefined,
  searchUsersLoading: false,
  searchUsersError: undefined,
  searchUsersList: []
}

interface GetSendToMeCategoriesType {
  userId: string,
  pages: number
}

export const getSendToMeCategories = createAsyncThunk('sendToUsers/getAllCategories', async ({
  userId,
  pages
}: GetSendToMeCategoriesType) => {
  let response;
  try {
    response = await getSendToMeCategoriesService(userId, pages)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})


export const sendCategoryToUser = createAsyncThunk('sendToUsers/sendCategoryToUser', async ({
  categoryId,
  categoryName,
  userId,
  receiversIds
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "receiversIds">) => {
  try {
    await sendToFriendCategoryService({ categoryId, categoryName, userId, receiversIds })
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const acceptSendToMeCategories = createAsyncThunk('sendToUsers/acceptSendToMeCategories', async ({categoryId, userId}: RequestParams) => {
  try {
    await acceptSendToMeCategoriesService({categoryId, userId})
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const cancelSendToMeCategories = createAsyncThunk('sendToUsers/cancelSendToMeCategories', async ({
  categoryId, 
  userId,
}: CancelSendToMeCategoryRequestParams) => {
  try {
    await cancelSendToMeCategoriesService({categoryId, userId})
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const searchForUsers = createAsyncThunk('sendToUsers/searchForUsers', async ({search, categoryId}: UserSearchParams) => {
  let response;
  try {
    response = await searchUsersService({search, categoryId})
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const sendToUsersCategoriesSlice = createSlice({
  name: 'sendToUsers',
  initialState,
  reducers: {
    cleanCancelCategoryError: (state) => {
      state.cancelCategoriesError = undefined;
    },
    cleanAcceptCategoryError: (state) => {
      state.acceptCategoriesError = undefined;
    },
    cleanSendToUserErrors: (state) => {
      state.searchUsersError = undefined;
      state.sendCategoryToUsersError = undefined;
    },
    cleanSearchResult: (state: CategoriesType) => {
      state.searchUsersList = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSendToMeCategories.pending, (state: CategoriesType) => {
        state.acceptCategoriesError = undefined
        state.getCategoriesError = undefined
        state.getCategoriesLoading = true
      })
      .addCase(getSendToMeCategories.fulfilled, (state: CategoriesType, action) => {
        state.getCategoriesLoading = false
        state.counts = action.payload.count
        const pages = Math.ceil(action.payload.count / 20)
        state.pages = action.payload.count / 20 < 0 ? 2 : pages
        const allCategories: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "description" | "creatorNames" | "cardsLength" | "categoryType">[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action.payload.results.forEach((category: any) => {
          const categoryObject = {
            categoryName: category.name,
            categoryId: category.id,
            userId: category.user,
            description: category.description,
            creatorNames: category.created_by_full_name,
            cardsLength: category.cards_length,
            categoryType: category.type_of_cards
          }
          allCategories.push(categoryObject)
        })
        state.allCategories = allCategories
      })
      .addCase(getSendToMeCategories.rejected, (state: CategoriesType, action) => {
        state.getCategoriesLoading = false
        state.getCategoriesError = action.error.message
      })
    builder
      .addCase(sendCategoryToUser.pending, (state: CategoriesType) => {
        state.sendCategoryToUsersError = undefined
        state.sendCategoryToUsersLoading = true
      })
      .addCase(sendCategoryToUser.fulfilled, (state: CategoriesType) => {
        state.sendCategoryToUsersLoading = false

      })
      .addCase(sendCategoryToUser.rejected, (state: CategoriesType, action) => {
        state.sendCategoryToUsersLoading = false
        state.sendCategoryToUsersError = action.error.message
      })
    builder
      .addCase(acceptSendToMeCategories.pending, (state: CategoriesType) => {
        state.acceptCategoriesError = undefined
        state.acceptFromUserLoading = true
      })
      .addCase(acceptSendToMeCategories.fulfilled, (state: CategoriesType) => {
        state.acceptFromUserLoading = false

      })
      .addCase(acceptSendToMeCategories.rejected, (state: CategoriesType, action) => {
        state.acceptFromUserLoading = false
        state.acceptCategoriesError = action.error.message
      })
    builder
      .addCase(cancelSendToMeCategories.pending, (state: CategoriesType) => {
        state.cancelCategoriesError = undefined
        state.cancelFromUserLoading = true
      })
      .addCase(cancelSendToMeCategories.fulfilled, (state: CategoriesType) => {
        state.cancelFromUserLoading = false

      })
      .addCase(cancelSendToMeCategories.rejected, (state: CategoriesType, action) => {
        state.cancelFromUserLoading = false
        state.cancelCategoriesError = action.error.message
      })
    builder
      .addCase(searchForUsers.pending, (state: CategoriesType) => {
        state.searchUsersError = undefined
        state.searchUsersLoading = true
      })
      .addCase(searchForUsers.fulfilled, (state: CategoriesType, actions) => {
        state.searchUsersLoading = false
        const allUsers: SearchUser [] = []
        actions.payload.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            avatar: user.avatar_url
          }
          allUsers.push(userObject)
        })
        state.searchUsersList = allUsers
      })
      .addCase(searchForUsers.rejected, (state: CategoriesType, action) => {
        state.searchUsersLoading = false
        state.searchUsersError = action.error.message
      })
  }
})


export const actions = sendToUsersCategoriesSlice.actions;

export const sendToUsersCategoriesSelector = (state: RootState) => state[sendToUsersCategoriesSlice.name];