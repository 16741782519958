import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserGroup } from "../common/user-groups";
import { SearchUser } from "../common/user-model";
import {
  addUsersToUserGroupService,
  createUserGroupsService,
  deleteUserGroupsService,
  editUserGroupsService,
  getMyUserGroupsPaginatedService,
  getMyUserGroupsService,
  getUserGroupDetailsService,
  removeUsersToUserGroupService,
  searchUsersGroupsService,
  UserSearchGroupsParams
} from "../services/userGroupsAxiosServices";
import { RootState } from "./store";


interface UserGroupsType {
  userGroups: UserGroup[];
  userGroupDetails: UserGroup;
  counts: number | null;
  pages: number;
  loadingGetUserGroups: boolean;
  errorGetUserGroups: string | undefined;
  loadingGetUserGroupDetails: boolean;
  errorGetUserGroupDetails: string | undefined;
  loadingCreateUserGroups: boolean;
  errorCreateUserGroups: string | undefined;
  loadingEditUserGroups: boolean;
  errorEditUserGroups: string | undefined;
  loadingDeleteUserGroups: boolean;
  errorDeleteUserGroups: string | undefined;
  loadingAddUserGroups: boolean;
  errorAddUserGroups: string | undefined;
  loadingRemoveUserGroups: boolean;
  errorRemoveUserGroups: string | undefined;
  searchUsersLoading: boolean;
  searchUsersError: string | undefined;
  searchUsersList: SearchUser[]
}

export const initialState: UserGroupsType = {
  userGroups: [],
  userGroupDetails: {
    id: "",
    name: "",
    author: "",
    myUsers: [],
    users: []
  },
  counts: null,
  pages: 0,
  loadingGetUserGroups: false,
  loadingDeleteUserGroups: false,
  errorDeleteUserGroups: undefined,
  errorGetUserGroups: undefined,
  loadingGetUserGroupDetails: false,
  errorGetUserGroupDetails: undefined,
  loadingCreateUserGroups: false,
  errorCreateUserGroups: undefined,
  loadingEditUserGroups: false,
  errorEditUserGroups: undefined,
  loadingAddUserGroups: false,
  errorAddUserGroups: undefined,
  loadingRemoveUserGroups: false,
  errorRemoveUserGroups: undefined,
  searchUsersLoading: false,
  searchUsersError: undefined,
  searchUsersList: []
}

export const getUserGroupsPaginated = createAsyncThunk('userGroups/getUserGroupsPaginated', async (currentPage: number) => {
  let response;
  try {
    response = await getMyUserGroupsPaginatedService(currentPage)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const getUserGroups = createAsyncThunk('userGroups/getUserGroups', async () => {
  let response;
  try {
    response = await getMyUserGroupsService()
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const createUserGroupAction = createAsyncThunk('userGroups/createUserGroupAction', async ({
  name,
  author,
}: Pick<UserGroup, "name" | "author">) => {
  try {
    await createUserGroupsService({ name, author })
  } catch (error) {
    console.error(error)
    throw error;
  }
})

export const editUserGroupAction = createAsyncThunk('userGroups/editUserGroupAction', async ({
  name,
  author,
  id
}: Pick<UserGroup, "name" | "author" | "id">) => {
  try {
    await editUserGroupsService({ name, author, id })
  } catch (error) {
    console.error(error)
    throw error;
  }
})

export const getUserGroup = createAsyncThunk('userGroups/getUserGroup', async (userGroupId: string) => {
  let response;
  try {
    response = await getUserGroupDetailsService(userGroupId)
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const addUsersToUserGroup = createAsyncThunk('userGroups/addUsersToUserGroup', async ({
  id,
  name,
  author,
  usersIds
}: Pick<UserGroup, "name" | "author" | "id" | "usersIds">) => {
  try {
    await addUsersToUserGroupService({ id, name, author, usersIds })
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const removeUsersToUserGroup = createAsyncThunk('userGroups/removeUsersToUserGroup', async ({
  id,
  name,
  author,
  usersIds
}: Pick<UserGroup, "name" | "author" | "id" | "usersIds">) => {
  try {
    await removeUsersToUserGroupService({ id, name, author, usersIds })
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const deleteUserGroup = createAsyncThunk('userGroups/deleteUserGroup', async (userGroupsId: string) => {
  try {
    await deleteUserGroupsService(userGroupsId)
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const searchForUsersGroup = createAsyncThunk('userGroups/searchForUsersGroup', async ({ search, userGroupsId }: UserSearchGroupsParams) => {
  let response;
  try {
    response = await searchUsersGroupsService({ search, userGroupsId })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const userGroupsSlice = createSlice({
  name: "userGroups",
  initialState,
  reducers: {
    cleanCreteError: (state: UserGroupsType) => {
      state.errorCreateUserGroups = undefined
      state.errorGetUserGroupDetails = undefined
      state.errorEditUserGroups = undefined
    },
    cleanEditError: (state: UserGroupsType) => {
      state.errorEditUserGroups = undefined
    },
    cleanUserGroupDetails: (state: UserGroupsType) => {
      state.userGroupDetails.name = ""
      state.userGroupDetails.id = ""
      state.userGroupDetails.author = ""
      state.userGroupDetails.myUsers = []
    },
    cleanDeleteUserGroupError: (state: UserGroupsType) => {
      state.errorDeleteUserGroups = undefined
    },
    cleanAddUsersError: (state: UserGroupsType) => {
      state.errorAddUserGroups = undefined
    },
    cleanRemoveUsersError: (state: UserGroupsType) => {
      state.errorRemoveUserGroups = undefined
    },
    cleanSearchResults: (state: UserGroupsType) => {
      state.searchUsersList = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserGroupsPaginated.pending, (state: UserGroupsType) => {
        state.loadingGetUserGroups = true;
        state.errorGetUserGroups = undefined;
      })
      .addCase(getUserGroupsPaginated.fulfilled, (state: UserGroupsType, action) => {
        state.loadingGetUserGroups = false
        state.counts = action.payload.count
        const pages = Math.ceil(action.payload.count / 20)
        state.pages = action.payload.count / 20 < 0 ? 2 : pages
        const allUserGroups: UserGroup[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action.payload.results.forEach((group: any) => {
          const userGroup: SearchUser[] = []
          group.receivers_users.forEach((user: any) => {
            const userObject = {
              id: user.id,
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              avatar: user.avatar_url
            }
            userGroup.push(userObject)
          })
          const userGroupObject = {
            id: group.id,
            name: group.name,
            author: group.author,
            myUsers: group.receivers_emails ? group.receivers_emails.split(',') : [],
            users: userGroup
          }
          allUserGroups.push(userGroupObject)
        })
        state.userGroups = allUserGroups
      })
      .addCase(getUserGroupsPaginated.rejected, (state: UserGroupsType, action) => {
        state.loadingGetUserGroups = false;
        state.errorGetUserGroups = action.error.message
      })
      .addCase(getUserGroups.pending, (state: UserGroupsType) => {
        state.loadingGetUserGroups = true;
        state.errorGetUserGroups = undefined;
      })
      .addCase(getUserGroups.fulfilled, (state: UserGroupsType, action) => {
        state.loadingGetUserGroups = false
        state.counts = action.payload.count
        const allUserGroups: UserGroup[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action.payload.forEach((group: any) => {
          const userGroup: SearchUser[] = []
          group.receivers_users.forEach((user: any) => {
            const userObject = {
              id: user.id,
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              avatar: user.avatar_url
            }
            userGroup.push(userObject)
          })
          const userGroupObject = {
            id: group.id,
            name: group.name,
            author: group.author,
            myUsers: group.receivers_emails ? group.receivers_emails.split(',') : [],
            users: userGroup
          }
          allUserGroups.push(userGroupObject)
        })
        state.userGroups = allUserGroups
      })
      .addCase(getUserGroups.rejected, (state: UserGroupsType, action) => {
        state.loadingGetUserGroups = false;
        state.errorGetUserGroups = action.error.message
      })
    builder
      .addCase(createUserGroupAction.pending, (state: UserGroupsType) => {
        state.loadingCreateUserGroups = true;
        state.errorCreateUserGroups = undefined;
      })
      .addCase(createUserGroupAction.fulfilled, (state: UserGroupsType) => {
        state.loadingCreateUserGroups = false;
      })
      .addCase(createUserGroupAction.rejected, (state: UserGroupsType, action) => {
        state.loadingCreateUserGroups = false;
        state.errorCreateUserGroups = action.error.message;
      })
    builder
      .addCase(editUserGroupAction.pending, (state: UserGroupsType) => {
        state.loadingCreateUserGroups = true;
        state.errorCreateUserGroups = undefined;
      })
      .addCase(editUserGroupAction.fulfilled, (state: UserGroupsType) => {
        state.loadingCreateUserGroups = false;
      })
      .addCase(editUserGroupAction.rejected, (state: UserGroupsType, action) => {
        state.loadingCreateUserGroups = false;
        state.errorCreateUserGroups = action.error.message;
      })
    builder
      .addCase(addUsersToUserGroup.pending, (state: UserGroupsType) => {
        state.loadingAddUserGroups = true;
        state.errorAddUserGroups = undefined;
      })
      .addCase(addUsersToUserGroup.fulfilled, (state: UserGroupsType) => {
        state.loadingAddUserGroups = false;
      })
      .addCase(addUsersToUserGroup.rejected, (state: UserGroupsType, action) => {
        state.loadingAddUserGroups = false;
        state.errorAddUserGroups = action.error.message;
      })
    builder
      .addCase(removeUsersToUserGroup.pending, (state: UserGroupsType) => {
        state.loadingRemoveUserGroups = true;
        state.errorRemoveUserGroups = undefined;
      })
      .addCase(removeUsersToUserGroup.fulfilled, (state: UserGroupsType) => {
        state.loadingRemoveUserGroups = false;
      })
      .addCase(removeUsersToUserGroup.rejected, (state: UserGroupsType, action) => {
        state.loadingRemoveUserGroups = false;
        state.errorRemoveUserGroups = action.error.message;
      })
    builder
      .addCase(getUserGroup.pending, (state: UserGroupsType) => {
        state.loadingGetUserGroupDetails = true;
        state.errorGetUserGroupDetails = undefined;
      })
      .addCase(getUserGroup.fulfilled, (state: UserGroupsType, action) => {
        state.loadingGetUserGroupDetails = false;
        state.userGroupDetails.id = action.payload.id;
        state.userGroupDetails.name = action.payload.name;
        state.userGroupDetails.author = action.payload.author;
        state.userGroupDetails.myUsers = action.payload.receivers_emails ? action.payload.receivers_emails.split(',') : [];
        const userGroup: SearchUser[] = []
        console.log(action.payload)
        action.payload.receivers_users.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            avatar: user.avatar_url
          }
          userGroup.push(userObject)
        })
        state.userGroupDetails.users = userGroup
      })
      .addCase(getUserGroup.rejected, (state: UserGroupsType, action) => {
        state.loadingGetUserGroupDetails = false;
        state.errorGetUserGroupDetails = action.error.message;
      })
    builder
      .addCase(deleteUserGroup.pending, (state: UserGroupsType) => {
        state.loadingDeleteUserGroups = true;
        state.errorDeleteUserGroups = undefined;
      })
      .addCase(deleteUserGroup.fulfilled, (state: UserGroupsType) => {
        state.loadingDeleteUserGroups = false;
      })
      .addCase(deleteUserGroup.rejected, (state: UserGroupsType, action) => {
        state.loadingDeleteUserGroups = false;
        state.errorDeleteUserGroups = action.error.message;
      })
    builder
      .addCase(searchForUsersGroup.pending, (state: UserGroupsType) => {
        state.searchUsersError = undefined
        state.searchUsersLoading = true
        state.searchUsersList = []
      })
      .addCase(searchForUsersGroup.fulfilled, (state: UserGroupsType, actions) => {
        state.searchUsersLoading = false
        const allUsers: SearchUser[] = []
        actions.payload.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            avatar: user.avatar_url
          }
          allUsers.push(userObject)
        })
        state.searchUsersList = allUsers
      })
      .addCase(searchForUsersGroup.rejected, (state: UserGroupsType, action) => {
        state.searchUsersLoading = false
        state.searchUsersError = action.error.message
      })
  }
})

export const actions = userGroupsSlice.actions;

export const userGroupsSliceSelector = (state: RootState) => state[userGroupsSlice.name];